<template>

<div class="image" :class="{'is-full': block.align === 0, 'is-left': block.align === 1, 'is-center': block.align === 2, 'is-right': block.align === 3}">

	<a :href="block.link" v-if="block.link"><img :src="block.file" class="image-file" :alt="block.caption" /></a>

	<img :src="block.file" class="image-file" :alt="block.caption" v-if="!block.link" />

	<div class="image-caption" v-if="block.caption">{{ block.caption }}</div>

</div>

</template>

<script>

import mixBlock from './mixin.js'

export default {

	mixins: [mixBlock]

}

</script>

<style scoped>

.image {
	margin-bottom: 20px;
}

.image-file {
	max-width: 100%;
}

.image.is-full .image-file {
	width: 100%;
	height: auto;
}

.image.is-left .image-file {
	margin: 0px auto 0px 0px;
}

.image.is-center .image-file {
	margin: 0px auto;
}

.image.is-right .image-file {
	margin: 0px 0px 0px auto;
}

.image-caption {
	font-size: 13px;
	text-align: center;
	line-height: 20px;
	color: #343434;
	padding: 4px 0px;
}

.image.is-left .image-caption {
	text-align: left;
}

.image.is-right .image-caption {
	text-align: left;
}

</style>
